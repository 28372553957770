import Sortable from '@stimulus-components/sortable'
import assignPositions from "../js/assignPositions";

export default class extends Sortable {
    // You can override the `end` method here.
    async onUpdate({oldIndex, item, newIndex}) {
        await super.onUpdate({item, newIndex})
        assignPositions(this.element);
    }
}

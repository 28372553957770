export default function assignPositions(rootElement) {
    let position = 1;

    rootElement.querySelectorAll('.nested-form-wrapper').forEach((wrapper) => {
        // we dont want to assign a number if destroyed is set
        const fieldWillPersist = wrapper.querySelector("input[name*='_destroy']").value !== '1'

        if (fieldWillPersist) {
            wrapper.querySelector('input[name*="position"]').value = position
            position++
        }
    })
}
// Entry point for the build script in your package.json
import * as ActiveStorage from "@rails/activestorage"
import "trix"
import "@rails/actiontext"

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// Load Bulma JS
require("./js/bulma")

ActiveStorage.start()
import "./controllers"

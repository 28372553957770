import NestedForm from '@stimulus-components/rails-nested-form'
import assignPositions from "../js/assignPositions";

export default class extends NestedForm {
    remove(e) {
        e.preventDefault()
        super.remove(e)
        assignPositions(this.element)
    }
}
